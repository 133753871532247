// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NavigationEntityFragment = { __typename?: 'Entity', id: string, stage: Types.EntityStage, subtype: { __typename: 'CCorpBusinessEntity', id: string } | { __typename: 'CLTTrust', id: string } | { __typename: 'CRTTrust', id: string } | { __typename: 'CustodialPersonalAccount', id: string } | { __typename: 'DonorAdvisedFund', id: string } | { __typename: 'GPBusinessEntity', id: string } | { __typename: 'GRATTrust', id: string } | { __typename: 'ILITTrust', id: string } | { __typename: 'IndividualPersonalAccount', id: string } | { __typename: 'InsurancePersonalAccount', id: string } | { __typename: 'IrrevocableTrust', id: string } | { __typename: 'JointPersonalAccount', id: string } | { __typename: 'LLCBusinessEntity', id: string } | { __typename: 'LPBusinessEntity', id: string } | { __typename: 'PrivateFoundation', id: string } | { __typename: 'QPRTTrust', id: string } | { __typename: 'QualifiedTuitionPersonalAccount', id: string } | { __typename: 'RetirementPersonalAccount', id: string } | { __typename: 'RevocableTrust', id: string } | { __typename: 'SCorpBusinessEntity', id: string } | { __typename: 'SLATTrust', id: string } | { __typename: 'SoleProprietorshipBusinessEntity', id: string }, household: { __typename?: 'Household', id: string } };

export type DuplicateEntityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DuplicateEntityMutation = { __typename?: 'Mutation', duplicateEntity: { __typename?: 'Entity', id: string } };

export type ConvertEntityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ConvertEntityMutation = { __typename?: 'Mutation', convertEntity: { __typename?: 'Entity', id: string } };

export type DeleteEntityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteEntityMutation = { __typename?: 'Mutation', deleteEntities: number };

export const NavigationEntityFragmentDoc = gql`
    fragment NavigationEntity on Entity {
  id
  stage
  subtype {
    __typename
    id
  }
  household {
    id
  }
}
    `;
export const DuplicateEntityDocument = gql`
    mutation DuplicateEntity($id: ID!) {
  duplicateEntity(entityId: $id) {
    id
  }
}
    `;
export type DuplicateEntityMutationFn = Apollo.MutationFunction<DuplicateEntityMutation, DuplicateEntityMutationVariables>;

/**
 * __useDuplicateEntityMutation__
 *
 * To run a mutation, you first call `useDuplicateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateEntityMutation, { data, loading, error }] = useDuplicateEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateEntityMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateEntityMutation, DuplicateEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateEntityMutation, DuplicateEntityMutationVariables>(DuplicateEntityDocument, options);
      }
export type DuplicateEntityMutationHookResult = ReturnType<typeof useDuplicateEntityMutation>;
export type DuplicateEntityMutationResult = Apollo.MutationResult<DuplicateEntityMutation>;
export type DuplicateEntityMutationOptions = Apollo.BaseMutationOptions<DuplicateEntityMutation, DuplicateEntityMutationVariables>;
export const ConvertEntityDocument = gql`
    mutation ConvertEntity($id: ID!) {
  convertEntity(entityId: $id) {
    id
  }
}
    `;
export type ConvertEntityMutationFn = Apollo.MutationFunction<ConvertEntityMutation, ConvertEntityMutationVariables>;

/**
 * __useConvertEntityMutation__
 *
 * To run a mutation, you first call `useConvertEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertEntityMutation, { data, loading, error }] = useConvertEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConvertEntityMutation(baseOptions?: Apollo.MutationHookOptions<ConvertEntityMutation, ConvertEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertEntityMutation, ConvertEntityMutationVariables>(ConvertEntityDocument, options);
      }
export type ConvertEntityMutationHookResult = ReturnType<typeof useConvertEntityMutation>;
export type ConvertEntityMutationResult = Apollo.MutationResult<ConvertEntityMutation>;
export type ConvertEntityMutationOptions = Apollo.BaseMutationOptions<ConvertEntityMutation, ConvertEntityMutationVariables>;
export const DeleteEntityDocument = gql`
    mutation DeleteEntity($id: ID!) {
  deleteEntities(ids: [$id])
}
    `;
export type DeleteEntityMutationFn = Apollo.MutationFunction<DeleteEntityMutation, DeleteEntityMutationVariables>;

/**
 * __useDeleteEntityMutation__
 *
 * To run a mutation, you first call `useDeleteEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntityMutation, { data, loading, error }] = useDeleteEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEntityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEntityMutation, DeleteEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEntityMutation, DeleteEntityMutationVariables>(DeleteEntityDocument, options);
      }
export type DeleteEntityMutationHookResult = ReturnType<typeof useDeleteEntityMutation>;
export type DeleteEntityMutationResult = Apollo.MutationResult<DeleteEntityMutation>;
export type DeleteEntityMutationOptions = Apollo.BaseMutationOptions<DeleteEntityMutation, DeleteEntityMutationVariables>;