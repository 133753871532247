import { noop } from 'lodash';
import {
  OptionsWithExtraProps,
  SnackbarKey,
  SnackbarMessage,
  VariantType,
} from 'notistack';
import { createContext } from 'react';

type ExposedNotIStackOpts = Pick<
  OptionsWithExtraProps<VariantType>,
  'autoHideDuration' | 'persist' | 'variant'
>;

export type FeedbackPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

export interface FeedbackOptions extends ExposedNotIStackOpts {
  position?: FeedbackPosition;
}

// Shortcut, commonly used in apollo onError onSuccess hooks
export type FeedbackFactoryFn = (
  message: SnackbarMessage,
  opts?: Omit<FeedbackOptions, 'message' | 'variant'>
) => () => void;

export type ShowFeedbackFn = (
  message: SnackbarMessage,
  opts?: FeedbackOptions
) => SnackbarKey;
export type HideFeedbackFn = () => void;
export interface FeedbackHook {
  createErrorFeedback: FeedbackFactoryFn;
  createSuccessFeedback: FeedbackFactoryFn;
  createWarningFeedback: FeedbackFactoryFn;
  showFeedback: ShowFeedbackFn;
  hideFeedback: HideFeedbackFn;
}

export const FeedbackContext = createContext<FeedbackHook>({
  createErrorFeedback: () => noop,
  createSuccessFeedback: () => noop,
  createWarningFeedback: () => noop,
  showFeedback: () => '',
  hideFeedback: noop,
});
