import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  ObservableQuery,
  Reference,
  split,
  StoreObject,
  TypePolicies,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { withScalars } from 'apollo-link-scalars';
import fetch from 'cross-fetch';
import {
  buildClientSchema,
  IntrospectionQuery,
  Kind,
  OperationTypeNode,
} from 'graphql';
import { createClient } from 'graphql-ws';
import { IncomingHttpHeaders } from 'http';
import isEqual from 'lodash.isequal';
import merge from 'lodash.merge';
import { AppProps } from 'next/app';
import { PostHog } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useMemo } from 'react';

import schemaJSON from '@/__generated__/graphql.schema.json';
import PossibleTypesJSON from '@/__generated__/possibleTypes.json';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { NO_GIFTING_SENTINEL } from '@/modules/gifting/proposal/designer/form';
import { localFederalEstateTaxPercentFormattedVar } from '@/modules/irs/useIRSConstants';
import {
  User,
  UserLocalMostRecentlyViewedEntityMapArgs,
  UserLocalMostRecentlyViewedWaterfallArgs,
} from '@/types/schema';
import { getAppVersion } from '@/utils/environmentUtils';
import { getPosthogSessionURL } from '@/utils/posthogUtils';

import {
  forceLoginLink,
  logErrorDetailsLink,
  printApolloErrorsMonitor,
  printNetworkStatusMonitor,
  reportNetworkErrorsLink,
} from './apolloLinks';
import { getGraphqlEndpoint, shouldRefetchQuery } from './client.utils';
import {
  mostRecentlyViewedEntityMapVar,
  mostRecentlyViewedWaterfallVar,
} from './reactiveVars';
import { scalarsMap } from './scalars';

const schema = buildClientSchema(schemaJSON as unknown as IntrospectionQuery);

export const typePolicies: TypePolicies = {
  ClientProfile: {
    keyFields: ['id'],
  },
  Entity: {
    keyFields: ['id'],
  },
  EstateWaterfall: {
    keyFields: ['id'],
    fields: {
      visualizationWithProjections: {
        // This field has no id, so we need to specify how to merge it.
        // We simply merge the objects together.
        merge(
          existing: StoreObject | Reference,
          incoming: StoreObject | Reference,
          { mergeObjects }
        ) {
          // If we encounter an incoming visualizationWithProjections, we merge the existing and incoming objects.
          // This means Query A can include keys omited in Query B and we will merge them together.
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  /*******************
   * @start Do not normalize types block
   * @description This block is used to prevent Apollo from normalizing certain types. These types
   * do not have an id field and are difficult to key, so we are explicitly telling Apollo to not
   * normalize them.
   */
  EstateValueMetrics: {
    keyFields: false,
  },
  ClientOverallPerformanceReport: {
    keyFields: false,
  },
  HouseholdMetrics: {
    keyFields: false,
  },
  EntityGraphNodeConfiguration: {
    keyFields: false,
  },
  GiftingProposalProjections: {
    keyFields: false,
  },
  /******************* @end Do not normalize types block */
  // We must use a combination of id and afterDeath to avoid weird caching issues where apollo transposes incorrect data between items in a list
  // Context: https://withluminary.slack.com/archives/C05BPQS8CG4/p1692291675704939?thread_ts=1692220364.855759&cid=C05BPQS8CG4
  EstateWaterfallVizNode: {
    keyFields: ['_cacheKey'],
  },
  EstateWaterfallViz: {
    keyFields: ['_cacheKey'],
  },
  TaxDetail: {
    keyFields: ['_cacheKey'],
  },
  TaxSummary: {
    keyFields: ['_cacheKey'],
  },
  // ['to', ['id', 'afterDeath']] --> translate to nested fields: { to: { id: string, afterDeath: AfterDeath } }
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  EstateWaterfallVizEdge: {
    keyFields: ['to', ['_cacheKey'], 'from', ['_cacheKey']],
  },
  InterestRateSummary: {
    // Identify an interest rate summary by its month and year
    keyFields: ['currentMonth', ['monthYearDisplay']],
  },
  FileDownload: {
    keyFields: ['downloadURL'],
  },
  IRSConstants: {
    fields: {
      localFederalEstateTaxPercentFormatted: {
        read(): string {
          // Read the percent string from the reactive var
          return localFederalEstateTaxPercentFormattedVar();
        },
      },
    },
  },
  GiftingProposalScenario: {
    fields: {
      /**
       * @description flag if the scenario is the baseline, no-gifting scenario;
       * always call this alongside `isDefault` in order to capture the system-defined default
       *
       * This check is repeated in GiftDesignerModelScenariosForm.utils, getGiftScenariosFromQueryData
       * for stability
       */
      isBaseline: {
        read(_, { readField }): boolean {
          const isDefault = readField<boolean>('isDefault') || false;
          const displayName = readField<string>('displayName') || '';
          return isDefault || displayName === NO_GIFTING_SENTINEL;
        },
      },
    },
  },
  User: {
    fields: {
      localMostRecentlyViewedWaterfall: {
        read(
          _,
          { args }: { args: UserLocalMostRecentlyViewedWaterfallArgs | null }
        ): User['localMostRecentlyViewedWaterfall'] {
          const householdId = args?.where?.householdId;
          // This read function accesses a reactive var, so it'll run whenever the var changes
          const waterfallVar = mostRecentlyViewedWaterfallVar();

          const key = `${LOCAL_STORAGE_KEYS.LAST_VIEWED_WATERFALL}_${householdId}`;
          const waterfallIdFromLocalStorage = localStorage.getItem(key);

          if (!waterfallVar && householdId && waterfallIdFromLocalStorage) {
            // We might need to provide an initial value from localStorage if
            // the var hasn't been set yet
            return {
              id: waterfallIdFromLocalStorage,
            };
          }

          return {
            id: waterfallVar?.id,
          };
        },
      },
      localMostRecentlyViewedEntityMap: {
        read(
          _,
          { args }: { args: UserLocalMostRecentlyViewedEntityMapArgs | null }
        ): User['localMostRecentlyViewedEntityMap'] {
          const householdId = args?.where?.householdId;
          const entityMapVar = mostRecentlyViewedEntityMapVar();

          const key = `${LOCAL_STORAGE_KEYS.LAST_VIEWED_ENTITY_MAP}_${householdId}`;
          const entityMapIdFromLocalStorage = localStorage.getItem(key);

          const id = entityMapVar
            ? entityMapVar.id
            : entityMapIdFromLocalStorage;
          return { id };
        },
      },
    },
  },
};

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

function createApolloClient(
  posthogClient: PostHog | null, // null in SSR
  headers: IncomingHttpHeaders | null = null
) {
  const passHeadersToFetch: typeof fetch = (url, init) => {
    const cookies: Record<string, string> = {};

    if (headers?.cookie) {
      cookies.Cookie = headers.cookie;
    }

    return fetch(url, {
      ...init,
      headers: {
        ...(init?.headers ?? {}),
        'Access-Control-Allow-Origin': '*',
        ...cookies,
      },
    }).then((response) => response);
  };

  const wsLinkUrl = getGraphqlEndpoint(headers, { isWebSocket: true });
  const httpLinkUri = getGraphqlEndpoint(headers, { isWebSocket: false });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsLinkUrl,
    })
  );

  const customHeaders: Record<string, string> = {};
  if (posthogClient) {
    const posthogSessionUrl = getPosthogSessionURL(posthogClient, {
      withTimestamp: true,
    });
    if (posthogSessionUrl) {
      customHeaders['x-lum-replay-url'] = posthogSessionUrl;
    }
  }

  const httpLink = createHttpLink({
    uri: httpLinkUri,
    fetchOptions: {
      mode: 'cors',
    },
    headers: customHeaders,
    credentials: 'same-origin',
    fetch: passHeadersToFetch,
  });

  // split based on operation type -- if a subscription, open a websocket connection; otherwise, send a standard HTTP request
  // https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === Kind.OPERATION_DEFINITION &&
        definition.operation === OperationTypeNode.SUBSCRIPTION
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all', // For refetched queries, return partial data if there are errors
      },
      mutate: {
        onQueryUpdated: (query: ObservableQuery<unknown>) => {
          // Prevent some queries from refetching automatically
          return shouldRefetchQuery(query.queryName);
        },
      },
    },
    ssrMode: typeof window === 'undefined',
    connectToDevTools: true, // https://www.apollographql.com/docs/react/development-testing/developer-tooling/
    link: from([
      printNetworkStatusMonitor,
      printApolloErrorsMonitor,
      forceLoginLink,
      reportNetworkErrorsLink,
      logErrorDetailsLink,
      withScalars({
        schema: schema,
        typesMap: scalarsMap,
        validateEnums: false,
        removeTypenameFromInputs: true,
      }),
      splitLink,
    ]),
    cache: new InMemoryCache({
      typePolicies,
      possibleTypes: PossibleTypesJSON.possibleTypes,
    }),
    name: 'WebClient',
    version: getAppVersion(),
  });
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export function useApollo(pageProps: AppProps['pageProps']) {
  const posthogClient = usePostHog();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- linter refactor
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initializeApollo(posthogClient, { initialState: state }),
    [posthogClient, state]
  );
  return store;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: AppProps<{
    props: Record<string, unknown>;
  }>['pageProps']
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

type InitialState = NormalizedCacheObject | undefined;

interface InitializeApollo {
  headers?: IncomingHttpHeaders | null;
  initialState?: InitialState | null;
}

export function initializeApollo(
  posthogClient: PostHog | null, // null in SSR
  { headers, initialState }: InitializeApollo = {
    headers: null,
    initialState: null,
  }
) {
  const client = apolloClient ?? createApolloClient(posthogClient, headers);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: unknown[], sourceArray: unknown[]) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s: unknown) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    client.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return client;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = client;

  return client;
}
