import { PostHog } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';

import { getEnvironment } from './environmentUtils';

export function getPosthogKey() {
  if (getEnvironment() === 'prod') {
    return 'phc_PTrx0j5vB6xy4Phx0QZdgdpf06lr4EL5mkiI1m9XFoq';
  }
  return 'phc_hrN1OL9g4qSO8DS1PR5JRlk2DA1U0dk3RJH6Yl7YJYq';
}

export function getPosthogSessionURL(
  posthogClient: PostHog,
  options?: { withTimestamp?: boolean }
) {
  return posthogClient.get_session_replay_url(options);
}

type UserEventProperties = Record<
  string,
  string | number | boolean | undefined | null
>;

/**
 * @description Track a user event with PostHog.
 * @param eventName - The name of the event to track. Use an [object] [verb] pattern,
 * where [object] is the entity that the behavior relates to, and [verb] is the behavior
 * itself. For example, "project created", "user signed up", or "invite sent".
 * @param properties - Optional properties to include with the event.
 */
export function useTrackUserEvent() {
  const posthogClient = usePostHog();
  return useCallback(
    (eventName: string, properties?: UserEventProperties) => {
      posthogClient.capture(eventName, properties);
    },
    [posthogClient]
  );
}
