import {
  darken,
  getContrastRatio,
  lighten,
  PaletteColor,
  ThemeOptions,
} from '@mui/material';
import { LinkProps } from '@mui/material/Link';
import { DataVisualizationColor } from '@mui/material/styles/createPalette';
// the below import is for https://mui.com/x/react-date-pickers/getting-started/#typescript
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import { ultraLight } from '@/styles/themes/paletteGenerator';
import { ShadowVariant } from '@/types/mui';

import { COLORS } from '../tokens/colors';
import { FONT_FAMILY, FONT_SIZING, FONT_WEIGHTS } from '../tokens/fonts';
import { zIndexes } from '../zIndexes';

function getContrastTextForColor(baseColor: string): string {
  const contrastRatio = getContrastRatio(baseColor, COLORS.PRIMITIVES.WHITE);
  // 4.5 is the default threshold for WCAG AA, but we use a slightly lower threshold because anecdotally,
  // white is slightly preferred
  const contrastThreshold = 3.3;

  // If contrast with white is high, the base color is dark
  // So we want to return a lightened version for contrast
  if (contrastRatio >= contrastThreshold) {
    return COLORS.PRIMITIVES.WHITE;
  }

  // If contrast with white is low, the base color is light
  // So we want to return a darkened version for contrast
  return darken(baseColor, 0.8); // Significantly darkened
}

export const generatePaletteFromBaseColor = (
  baseColor: string
): PaletteColor => {
  return {
    main: baseColor,
    light: lighten(baseColor, 0.95),
    dark: darken(baseColor, 0.1),
    contrastText: getContrastTextForColor(baseColor),
  };
};

export const generateDataVisualizationPaletteFromBaseColor = (
  baseColor: string
): DataVisualizationColor => {
  return {
    main: baseColor,
    light: lighten(baseColor, 0.2),
    dark: darken(baseColor, 0.1),
    contrastText: getContrastTextForColor(baseColor),
    lighter: lighten(baseColor, 0.4),
    ultraLight: ultraLight(baseColor),
  };
};

// https://mui.com/material-ui/integrations/routing/#global-theme-link
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to'];
    external?: RouterLinkProps['reloadDocument'];
    target?: RouterLinkProps['target'];
  }
>(function LinkBehavior(props, ref) {
  const { href, external, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return (
    <RouterLink ref={ref} to={href} reloadDocument={external} {...other} />
  );
});

export const SPACING_CONSTANT = 8;
const INPUT_PADDING = '10px 14px';

export const THEME_SHADOWS: Record<ShadowVariant, string> = {
  xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  sm: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
  md: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
  lg: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
  xl: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
  xxl: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
  xxxl: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',

  innerShadowLeftward:
    '-2px 0px 4px -2px rgba(16, 24, 40, 0.06) inset, -4px 0px 8px -2px rgba(16, 24, 40, 0.10) inset',
  innerShadowRightward:
    '4px 0px 8px -2px rgba(16, 24, 40, 0.10) inset, 2px 0px 4px -2px rgba(16, 24, 40, 0.06) inset',
  // same as md but with the shadow inset
  mdInset:
    'inset 0px 2px 4px -2px rgba(16, 24, 40, 0.06), inset 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
  // same as mdInset but with the shadow inverted as well
  mdInsetInvert:
    '0px -2px 4px -2px rgba(16, 24, 40, 0.06) inset, 0px -4px 8px -2px rgba(16, 24, 40, 0.10) inset',
  // same as lg but with the shadow cast upwards
  lgYInvert:
    '0px -4px 6px -2px rgba(16, 24, 40, 0.03), 0px -12px 16px -4px rgba(16, 24, 40, 0.08)',

  // same as md but with the shadow cast upwards
  mdYInvert:
    '0px -2px 4px -2px rgba(16, 24, 40, 0.06), 0px -4px 8px -2px rgba(16, 24, 40, 0.10)',
  rightInvert:
    '4px 0px 8px -2px rgba(16, 24, 40, 0.1), 2px 0 4px -2px rgba(16, 24, 40, 0.06)',
  rightInvertInset:
    'inset 4px 0px 8px -2px rgba(16, 24, 40, 0.1), inset 2px 0 4px -2px rgba(16, 24, 40, 0.06)',
  drawerShadowInset:
    'inset 0 20px 24px -4px rgba(16, 24, 40, 0.08), inset 0 8px 8px -4px rgba(16, 24, 40, 0.03)',
};

export const COMMON_THEME_PROPERTIES: ThemeOptions = {
  spacing: SPACING_CONSTANT,
  typography: {
    fontFamily: FONT_FAMILY,
    fontWeightLight: FONT_WEIGHTS.light,
    fontWeightRegular: FONT_WEIGHTS.regular,
    fontWeightMedium: FONT_WEIGHTS.medium,
    fontWeightBold: FONT_WEIGHTS.bold,
    h1: {
      ...FONT_SIZING.xxl,
      fontWeight: FONT_WEIGHTS.bold,
      color: COLORS.NAVY[600],
    },
    h2: {
      ...FONT_SIZING.xl,
      fontWeight: FONT_WEIGHTS.bold,
      color: COLORS.NAVY[600],
    },
    h3: {
      ...FONT_SIZING.lg,
      fontWeight: FONT_WEIGHTS.bold,
      color: COLORS.NAVY[600],
    },
    h4: {
      ...FONT_SIZING.md,
      fontWeight: FONT_WEIGHTS.bold,
      color: COLORS.NAVY[600],
    },
    h5: {
      ...FONT_SIZING.sm,
      fontWeight: FONT_WEIGHTS.bold,
      color: COLORS.NAVY[900],
    },
    h6: {
      ...FONT_SIZING.xs,
      fontWeight: FONT_WEIGHTS.bold,
      color: COLORS.NAVY[900],
    },
    subtitle1: {
      ...FONT_SIZING.sm,
      fontWeight: FONT_WEIGHTS.regular,
      color: COLORS.GRAY[500],
    },
    subtitle2: {
      ...FONT_SIZING.xs,
      fontWeight: FONT_WEIGHTS.regular,
      color: COLORS.GRAY[500],
    },
    body1: {
      ...FONT_SIZING.sm,
      fontWeight: FONT_WEIGHTS.regular,
      color: COLORS.NAVY[900],
    },
    body2: {
      ...FONT_SIZING.md,
      fontWeight: FONT_WEIGHTS.regular,
      color: COLORS.NAVY[900],
    },
    button: {
      // the default MUI style has this set to "uppercase"; reset that here.
      textTransform: 'none',
      ...FONT_SIZING.sm,
      fontWeight: FONT_WEIGHTS.medium,
      // the color will be variable based on the treatment of the button,
      // e.g. primary vs secondary
    },
    // the button2 styling is only used for the xs button as of 3/2024
    button2: {
      ...FONT_SIZING.xs,
      lineHeight: '1rem',
      fontWeight: FONT_WEIGHTS.medium,
      fontFamily: FONT_FAMILY, // for some reason, the top-level font family isn't getting applied to this
      // the color will be variable based on the treatment of the button,
      // e.g. primary vs secondary
    },
    label: {
      ...FONT_SIZING.sm,
      fontWeight: FONT_WEIGHTS.semibold,
      color: COLORS.NAVY[600],
    },
    label2: {
      ...FONT_SIZING.md,
      fontWeight: FONT_WEIGHTS.semibold,
      color: COLORS.NAVY[600],
    },
  },
  shape: {
    borderRadius: 4,
  },
  // if these aren't all set to `none`, MUI will pull the default shadow styles
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  palette: {
    contrastThreshold: 2.5,
    common: {
      black: COLORS.PRIMITIVES.BLACK,
      white: COLORS.PRIMITIVES.WHITE,
    },
    divider: COLORS.NAVY[50],
    links: generatePaletteFromBaseColor(COLORS.NAVY[500]),
    buttons: generatePaletteFromBaseColor(COLORS.NAVY[500]),
    dataVisualizationPrimary: generateDataVisualizationPaletteFromBaseColor(
      COLORS.NAVY[300]
    ),
    dataVisualizationSecondary: generateDataVisualizationPaletteFromBaseColor(
      COLORS.TEAL[300]
    ),
    dataVisualizationTertiary: generateDataVisualizationPaletteFromBaseColor(
      COLORS.BLUE[300]
    ),
    dataVisualizationNegative: generateDataVisualizationPaletteFromBaseColor(
      COLORS.ORANGE[200]
    ),
    // https://www.figma.com/file/FlleF4k1X9St9vv3EgiSGY/Design-System?node-id=21%3A487&mode=dev
    shadows: THEME_SHADOWS,
    education: generatePaletteFromBaseColor(COLORS.FUNCTIONAL.WARNING[400]),
  },
  // TODO: figure out why this was breaking modals
  // breakpoints: {
  //   keys: ['sm', 'md', 'lg', 'xl'],
  //   values: {
  //     sm: 0,
  //     md: 1024,
  //     lg: 1280,
  //     xl: 1440,
  //   },
  // },
  zIndex: {
    drawer: zIndexes.CONTEXTUAL_HELP,
    modal: zIndexes.MODAL,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: ({ theme }) => ({
          padding: INPUT_PADDING,
          ...theme.typography.body1,
        }),
        root: {
          margin: 0,
        },
      },
      defaultProps: {},
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: INPUT_PADDING,
        },
        input: {
          // since we want the padding/height of this component to be consistent
          // with the rest of the inputs, we're setting that on inputRoot and setting
          // it to 0 here; there were paddings in both places with the default implementation.
          paddingTop: '0px !important',
          paddingBottom: '0px !important',
          marginTop: '0px !important',
        },
        popper: ({ theme }) => ({
          boxShadow: theme.palette.shadows.lg,
          border: `1px solid ${COLORS.GRAY[200]}`,
          marginTop: `${theme.spacing(0.5)} !important`,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          margin: 0,
          marginTop: theme.spacing(0.5),
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
        LinkComponent: LinkBehavior,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${COLORS.GRAY[200]}`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          ':hover': {
            backgroundColor: COLORS.FUNCTIONAL.HOVER,
          },
          '&.Mui-selected': {
            backgroundColor: COLORS.GRAY[100],
          },
          paddingTop: theme.spacing(1.25),
          paddingBottom: theme.spacing(1.25),
          paddingLeft: theme.spacing(1.75),
          paddingRight: theme.spacing(1.75),
        }),
        divider: {
          borderColor: COLORS.GRAY[300],
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          boxShadow: theme.palette.shadows.lg,
          border: `1px solid ${COLORS.GRAY[200]}`,
          marginTop: theme.spacing(0.5),
        }),
      },
    },
    MuiCalendarOrClockPicker: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: theme.palette.shadows.lg,
          border: `1px solid ${COLORS.GRAY[200]}`,
          marginTop: theme.spacing(0.5),
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&.MuiDivider-light': {
            borderColor: COLORS.PRIMITIVES.WHITE,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiPaper-root': {
            padding: theme.spacing(3),
          },
        }),
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: COLORS.GRAY[300],
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: { style: { whiteSpace: 'normal' } }, // ListItemText should wrap text by default, you can override this with an ellipsis if you need to
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '& .MuiListItemIcon-root': {
            // Without setting the min-width here, the icon of the selected option
            // has a default width of 56px in the select input, which causes awkward
            // spacing between the icon and the text. 36px is the min-width of
            // the icon in the dropdown (as set by MUI).
            minWidth: 36,
          },
        },
      },
    },
  },
};
